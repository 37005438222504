@import "../variables";

.package-single-view {
    padding: 5px;
    padding-top: 10px;
    margin: 5px;

    .header {

        //.MuiGrid2-container {
        //    width: 100%;
        //
        //    & > svg {
        //        width: 8%;
        //        cursor: pointer;
        //        color: $primaryColor;
        //    }
        //
        //    .title {
        //        width: 75%;
        //        text-align: center;
        //        font-size: 25px;
        //    }
        //
        //    .pdf {
        //        width: 6%;
        //        cursor: pointer;
        //        align-items: center;
        //
        //        svg {
        //            color: $primaryColor;
        //        }
        //    }
        //}

        .title {
            width: 100%;
            text-align: center;
            font-size: 25px;
        }

        svg {
            color: $primaryColor;
            margin-top: 3px;
        }

        .pdf {
            width: 100%;
            align-items: center;
            cursor: pointer;

            svg {
                width: 100%;
            }

            div {
                width: 100%;
                font-size: 16px;
                color: #484848;
                text-align: center;
            }
        }

        .detail {
            position: relative;

            .text {
                width: 100%;
                float: left;
                text-align: center;

                span {
                    width: 50%;
                    padding: 0 10px;
                    font-size: 16px;
                    color: #484848;

                    &:nth-child(2) {
                        border-left: 1px solid $primaryColor;
                    }
                }
            }
        }
    }

    .question-card {
        margin-top: 20px;

        .panel-header {
            padding-left: 15px;

            .expand-icon {
                color: $primaryColor;
            }
        }

        .body {
            img {
                max-width: 100%
            }
        }

        .save-icon {
            text-align: end;

            svg {
                margin-right: 20px;
                cursor: pointer;
                color: $primaryColor;
            }
        }
    }

    .timer-container {
        position: fixed;
        top: 50%;
        float: right;
        z-index: 3;
        display: flex;
        right: 15px;

        @media screen and (max-width: $mobile) {
            padding-top: 20px;
            top: 80%;
        }

        .time {
            position: absolute;
            right: 80px;
            font-size: 40px;
            display: flex;

            svg {
                background-color: red;

                &:hover {
                    color: white;
                    padding: 13px;
                }
            }

            span {
                margin-right: 30px;
            }
        }

        svg {
            cursor: pointer;
            padding: 10px;
            background-color: rgb(3, 152, 159, 0.85);
            color: white;
            border-radius: 3px;
            -webkit-box-shadow: -10px 0px 13px -7px #707070, 10px 0px 13px -7px #8f8f8f, 5px 5px 15px 5px rgba(0,0,0,0);
            box-shadow: -10px 0px 13px -7px #5e5e5e, 10px 0px 13px -7px #656565, 5px 5px 15px 5px rgba(0,0,0,0);

            &:hover {
                color: red;
                padding: 13px;
            }
        }
    }
}


.pdf-download {
    .download-option {
        display: flex;
        justify-content: center;

        .compact, .separate {
            display: flex;
            width: 60%;
            padding: 10px 40px;
            border: 1px solid #a2a2a2;
            margin: 10px 0;
            border-radius: 10px;
            -webkit-box-shadow: -1px 0px 13px -2px #858585;
            box-shadow: -1px 0px 13px -2px #858585;
            text-decoration: none;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 10px;
            }
        }
    }
}

.cannot-download {
    div {
        p {
            text-align: center;
        }

        svg {
            color: $primaryColor;
            width: 50px;
            height: 50px;
        }
    }
}
