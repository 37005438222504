@import "../variables";

.home {
    & > .header {
        padding: 5px;
        margin: 5px;
        font-size: 30px;
        font-family: "Sofia", sans-serif;
        display: flex;
        align-content: center;
        margin-left: 50px;

        @media screen and (max-width: $mobile) {
            margin-left: 10px;
        }

        svg {
            width: 50px;
            height: 50px;

            rect, g & > path {
                fill: #efefef;
            }

            & > g {
                fill: white;
            }
        }
    }

    .filter-container {
        margin: 5px;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        padding: 0 20px;
        margin-bottom: 30px;

        @media (min-width: 1400px) {
            max-width: 1400px !important;
        }

        @media (min-width: 1200px) {
            max-width: 1200px;
        }

        .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            div {
                border-radius: 6px;
                background-color: $primaryColor;
                margin: 5px;
                width: 100%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px;

                .filter-icon {
                    cursor: pointer;
                    padding: 3px;
                }

                span {
                    margin-left: 10px;
                }
            }


        }

        .sorting {

            fieldset {
                border: 1px solid #aaaaaa;
                background: #ffffff;
                color: #4b5563;
                border-radius: 6px;
                padding-bottom: 4px;
                padding-top: 4px;
                font-size: 14px;
                cursor: pointer;

                .p-dropdown-label {
                    font-size: 13px;
                }
            }

            p {
                margin: 3px;

                @media screen and (max-width: $mobile) {
                    margin: 0;
                }
            }
        }
    }

    .MuiContainer-maxWidthLg {
        @media (min-width: 1400px) {
            max-width: 1400px !important;
        }
    }
}

.p-sidebar-right .p-sidebar,
.p-sidebar-bottom .p-sidebar {
    background-color: white;
    padding: 30px;
    height: 60%;

    h3 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .options {
        margin-bottom: 15px;

        div {
            margin-left: 15px;

            label > span {
                padding: 5px;
            }
        }


    }
}

.p-sidebar-right .p-sidebar, {
    height: 100%;
}

.css-fyswvn {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.bottom-space {
    margin-bottom: 100px;
    visibility: hidden;
}
