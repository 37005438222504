@import "./variables";

.login-container {
    .oauth2-option {
        display: flex;
        justify-content: center;
    }

    .oauth2-option .google,
    .oauth2-option .email,
    .oauth2-option .facebook {
        display: flex;
        width: 60%;
        padding: 10px 40px;
        border: 1px solid #a2a2a2;
        margin: 10px 0;
        border-radius: 10px;
        -webkit-box-shadow: -1px 0px 13px -2px #858585;
        box-shadow: -1px 0px 13px -2px #858585;
        text-decoration: none;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            width: 80%;
        }
    }

    .oauth2-option .email {
        input[type="email"] {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            border: 1px solid #a2a2a2;
            border-radius: 4px;
            font-size: 14px;
            background-color: inherit;

            @media screen and (max-width: $mobile) {
                min-width: 100%;
            }
        }

        input[type="email"]:focus {
            border-color: #4CAF50;
            outline: none;
            box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
        }

        svg {
            margin-left: 20px;
            cursor: pointer;

            &.valid {
                color: $primaryColor;
            }

            &.invalid {
                color: grey;
            }
        }
    }

    a {
        span {
            margin-left: 10px;
        }
    }

}

.profile-container {
    .loading {
        z-index: 3;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.35);
        height: 100%;
    }
}

