$mobile: 756px;

.package-card {
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        border: 1px solid #707070;
    }

    .header {
        position: relative;
        padding-top: 10px;

        svg {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
            color: #03989E;

            @media screen and (max-width: $mobile) {
                width: 20px;
                height: 20px;
            }
        }
    }

    .margin {
        width: 20%
    }

    hr {
        border-top: 1px solid #03989E;
        width: 80%;
        float: left;
        margin-left: 10%;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .body {
        font-size: 15px;
        margin-bottom: 20px;
        display: inline-block;

        span {
            width: 50%;
            padding: 0 10px;

            &:nth-child(2) {
                border-left: 1px solid #03989E;
            }
        }
    }

    .footer {
        background-color: #03989E;
        padding: 5px 10px;
        color: white;
        text-align: right;

    }
}
