@import "../variables";

@media (min-width: 1400px) {
    .css-5c1adp-MuiContainer-root {
        max-width: 1400px!important;
    }
}

.price-container {
    padding-top: 30px;

    @media screen and (max-width: $mobile) {
        padding-top: 20px;
    }

    .header {
        h2 {
            text-align: center;
            width: 100%;
        }

        p {
            font-family: 'Courier New', monospace;
            padding: 0 15%;
            text-align: justify;

            @media screen and (max-width: $mobile) {
                padding: 0 9%;
            }
        }
    }

    .card-container {
        margin-bottom: 80px;
    }

    .card {
        background-color: white;
        border-radius: 5px;
        padding: 15px 10px 15px 20px;

        h2 {
            margin: 0;
        }

        h4 {
            margin: 10px 0;
        }

        p {
            margin: 0;
        }

        ul {
            list-style: none;
            padding-left: 8px;

            svg {
                margin-right: 10px;
                width: 24px;
                height: 24px;

                &.active {
                    color: green;
                }

                &.inactive {
                    color: red;
                }
            }
        }
    }

    .buttons {
        margin-top: 30px;
        width: 100%;
        text-align: center;
        margin-bottom: 100px;
        a {
            background: $primaryColor;
            color: white;
            border: none;
            border-radius: 5px;
            padding: 10px 25px;
            font-size: 18px;
            min-width: 350px;
            margin-bottom: 25px;
            display: inline-block;
            text-align: center;
            text-decoration: none;

            margin-left: 30px;

            @media screen and (max-width: $mobile) {
                margin-left: unset;
            }
        }
    }
}
