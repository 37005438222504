@import "./variables";

.price-modal {
    .buttons {
        margin-top: 30px;
        width: 100%;
        text-align: center;
        a {
            background: $primaryColor;
            color: white;
            border: none;
            border-radius: 5px;
            padding: 10px 25px;
            font-size: 18px;
            min-width: 350px;
            margin-bottom: 25px;
            display: inline-block;
            text-align: center;
            text-decoration: none;
        }
    }
}
