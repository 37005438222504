@import "../variables";

.saved-questions-page {
    margin: 0 5%;

    h2 {
        text-align: center;
    }

    .question-card {
        margin-top: 20px;

        .panel-header {
            padding-left: 15px;

            .expand-icon {
                color: $primaryColor;
            }

            p {
                width: 100%;

                span {
                    float:left;
                    clear:left;

                    &:nth-child(2) {
                        float:right;
                        clear:right;
                        margin-right: 20px;
                    }
                }
            }
        }

        .body {
            img {
                max-width: 100%
            }

            .save-icon {
                text-align: end;

                svg {
                    margin-right: 20px;
                    cursor: pointer;
                    color: $primaryColor;
                }
            }
        }
    }
}
