@import "../variables";

.profile-container {

    .login-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 40%;

        @media screen and (max-width: $mobile) {
            width: 92%;
        }
    }

    ul {
        list-style: none;

        li {
            font-size: 20px;
            margin: 15px 0 ;
        }
    }

    .logout {
        svg {
            padding: 5px;
            color: $primaryColor;

            &:hover {
                background-color: white;
            }
        }
    }

    .back-root-domain {
        width: 100%;
        opacity: 0.97;
        position: absolute;
        bottom: 56px;

        &:hover {
            background-color: #dbdbdb;
        }

        //@media screen and (max-width: $mobile) {
        //    bottom: -14px;
        //}

        a {
            display: flex;
            width: 100%;
            justify-content: center;
            color: #4e4e4e;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
            font-size: 17px;
            -webkit-flex: 1;

            svg {
                color: $primaryColor;
                margin-left: 20px;
            }
        }
    }
}
